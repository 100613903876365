<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" class="ma-0 pt-6">
        <v-row>
          <export-excel
            v-if="exportar.includes('EXCEL')"
            class="mx-1 my-1"
            :data="exportarDataExcel()"
            :name="nombreReporte + '.xls'"
            :before-generate="
              () => {
                exportandoExel = true
              }
            "
            :before-finish="
              () => {
                exportandoExel = false
              }
            "
            style="width: unset"
          >
            <v-btn x-small :disabled="datos.length == 0"    color="success">
              EXCEL
              <v-icon small>{{ icons.mdiFileExcel }}</v-icon>
            </v-btn>
          </export-excel>
          <v-btn
            x-small
            class="mx-1 my-1"
            v-if="exportar.includes('PDF')"
            :disabled="datos.length == 0"
            @click="ImprimirPDF()"
            color="error"
          >
            PDF
            <v-icon small>{{ icons.mdiFilePdf }}</v-icon>
          </v-btn>
          <div class="mx-1 mt-1"  v-if="columnas">
            <v-menu v-if="datos.length > 0" v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small color="secondary" v-bind="attrs" v-on="on"> Columnas </v-btn>
              </template>
              <v-card> 
                <v-list>
                  <v-list-item-group v-model="columasSelect" multiple style="height: 200px; overflow-y: auto;" >
                    <template v-for="(item, i) in headers">
                      <v-list-item :key="`item-${i}`" :value="item" active-class="primary--text  ">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                          </v-list-item-content> 
                          <v-list-item-action>
                            <v-checkbox :input-value="active" color="primary  "> </v-checkbox>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
         
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <!--<v-text-field  clearable outlined dense v-model="search" :append-icon="icons.mdiMagnify" label="Filtrar"
                    v-if="datos.length > 0" single-line hide-details>
                </v-text-field>-->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="datos.length == 0" class="text-center">
        <span>No hay resultados que concidan con la busqueda</span>
      </v-col>
      <v-col cols="12" v-else>
        <v-simple-table fixed-header dense :height="height == undefined ? '300px' : height + 'px'">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(item, i) in columasSelect" :key="i" style="padding-left: 10px; padding-right: 10px">
                  <small>{{ item.text }}</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in datos" :key="i">
                <td v-for="(col, y) in columasSelect" :key="y">
                  <div v-if="col.value == 'accion'">
                    <v-tooltip bottom v-if="acciones.includes('VER')">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="pr-2" v-bind="attrs" v-on="on" @click="GetVer(item)">
                          <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                        </a>
                      </template>
                      <span>Ver</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="acciones.includes('EDITAR')">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditar(item)">
                          <v-icon color="info">{{ icons.mdiPencil }}</v-icon>
                        </a>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="acciones.includes('ELIMINAR')">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEliminar(item)">
                          <v-icon color="error">{{ icons.mdiDelete }}</v-icon>
                        </a>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </div>

                  <div v-else>
                    <span v-if="col.format == undefined">{{ findObjectByLabel(item, col.value) }}</span>
                    <span v-else-if="col.format.text == true" :class="[col.format.class]">{{
                      findObjectByLabel(item, col.value)
                    }}</span>
                    <small v-else-if="col.format.small == true"
                      :class="[
                        col.format.class,
                        col.format.color ? col.format.color.condicional(findObjectByLabel(item, col.value)) : '',
                      ]"
                    > 
                      <span v-if="col.format.date == true" :class="[col.format.class]">{{
                        FuncionesGenerales.formatoFecha(findObjectByLabel(item, col.value), 1)
                      }}</span>
                      <span v-else-if="col.format.dateTime == true" :class="[col.format.class]">{{
                        FuncionesGenerales.formatoFecha(findObjectByLabel(item, col.value), 3)
                      }}</span>
                      <span v-else-if="col.format.time == true" :class="[col.format.class]">{{
                        FuncionesGenerales.formatoFecha(findObjectByLabel(item, col.value), 2)
                      }}</span>
                      <span v-else>
                        {{ findObjectByLabel(item, col.value) }}
                      </span>
                    </small>
                    <span v-else-if="col.format.small == false && (col.format.date || col.format.dateTime ||col.format.time )">

                      <span v-if="col.format.date == true" :class="[col.format.class]">{{
                        FuncionesGenerales.formatoFecha(findObjectByLabel(item, col.value), 1)
                      }}</span>
                      <span v-else-if="col.format.dateTime == true" :class="[col.format.class]">{{
                        FuncionesGenerales.formatoFecha(findObjectByLabel(item, col.value), 3)
                      }}</span>
                      <span v-else-if="col.format.time == true" :class="[col.format.class]">{{
                        FuncionesGenerales.formatoFecha(findObjectByLabel(item, col.value), 2)
                      }}</span>
                    </span>

                    <v-chip
                      v-else-if="col.format.chip == true"
                      class="ma-2"
                      :class="[col.format.class]"
                      :color="col.format.color[col.format.valores.indexOf(findObjectByLabel(item, col.value))]"
                      :small="col.format.size == 'small'"
                      :x-small="col.format.size == 'x-small'"
                    >
                      {{ findObjectByLabel(item, col.value) }}
                    </v-chip>
                    <v-chip
                      v-else-if="col.format.chip2 == true"
                      class="ma-2"
                      :class="[col.format.class]"
                      :color="col.format.color ? col.format.color.condicional(findObjectByLabel(item, col.value)) : ''"
                      :small="col.format.size == 'small'"
                      :x-small="col.format.size == 'x-small'"
                    >
                      {{ findObjectByLabel(item, col.value) }}
                    </v-chip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <pdf ref="pdfRef"></pdf>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiFileExcel, mdiFilePdf, mdiMagnify, mdiPencil, mdiDelete, mdiEye } from '@mdi/js'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from './Pdf.vue'
import { stringify } from 'querystring'
import moment from 'moment'
import store from '@/store'
export default {
  components: {
    Pdf,
  },
  props: {
    exportar: Array,
    datosIn: Array,
    nombreReporte: String,
    cabeceraIn: Array,
    acciones: Array,
    height: Number,
    fontSize: {
      type:Number,
      default: 7
    },
    columnas:{
      type: Boolean,
      default:true
    },
    orientacionReporte: {
      type: String,
      default: 'p',
    },
  },
  setup(props, contex) {
    const menu = ref(null)
    const headers = ref([])
    const datos = ref()
    const pdfRef = ref(null)
    const columasSelect = ref([])
    const exportandoExel = ref(false)
    const search = ref('')
    const exportarDataExcel = () => {
      console.log(columasSelect.value)

      let data = []
      datos.value.forEach(element => {
        let objet = {}
        columasSelect.value.forEach(columna => {
          let valor = findObjectByLabel(element, columna.value)
          if (!isNaN(valor)) {
            objet = { ...objet, [columna.text]: valor.toString().replace('.', ',') }
          } else {
            objet = { ...objet, [columna.text]: valor }
          }
        })
        data.push(objet)
      })
      console.log(data)
      return data
    }
    watch(props, () => {
      headers.value = props.cabeceraIn
      datos.value = props.datosIn
      columasSelect.value = props.cabeceraIn.filter(item => item.visible != false)
    })
    onBeforeMount(() => {
      headers.value = props.cabeceraIn
      datos.value = props.datosIn

      if (props.acciones != undefined) {
        if (headers.value.find(item => item.value == 'accion') == undefined) {
          headers.value.push({ text: 'Accion', value: 'accion', visible: true })
        }
      }
      columasSelect.value = props.cabeceraIn.filter(item => item.visible != false)
    })

    const GetEditar = item => {
      contex.emit('GetEditar', item)
    }

    const GetVer = item => {
      contex.emit('GetVer', item)
    }
    const GetEliminar = item => {
      contex.emit('GetEliminar', item)
    }
    var findObjectByLabel = function (obj, label) {
      if (label.includes('.')) {
        let array = label.split('.')
        const primero = array[0]
        array = array.splice(1).join('.')
        var foundLabel = findObjectByLabel(obj[primero], array)
        if (foundLabel) {
          return foundLabel
        }
      } else {
        if (obj != null) {
          return obj[label]?.toString()
        } else {
          return ''
        }
      }
    }
    const ImprimirPDF = () => {
      const bodyPdf = []
      const headPdf = []

      columasSelect.value.forEach(element => {
        headPdf.push({ content: element.text })
      })

      datos.value.forEach(element => {
        let arr = []
        columasSelect.value.forEach(columna => {
          arr.push(findObjectByLabel(element, columna.value))
        })

        bodyPdf.push(arr)
      })

      const datosInit = {
        orientacion: props.orientacionReporte,
        nombreDocumento: props.nombreReporte,
        cabecera1: {
          visible: true,
        },
        titulo: {
          visible: true,
          posicion: 'center',
          texto: props.nombreReporte,
          TamanoLetras: 12,
          color: '#7B7B7B',
        },
        cabecera2: {
          visible: true,
          datos: [
            { texto: 'Exportado por: ' + store.state.user.nombre +  ' '+  store.state.user.apellido  }, 
          { texto: 'Fecha: ' + moment().format('YYYY-MM-DD h:mm:ss a') },
         
          ],
        },
        sub1: {
          visible: false,
          datos: [],
        },
        sub2: {
          visible: false,
          datos: [],
        },
        cuerpo1: {
          visible: true,
          datos: [
            {
              titulo: { texto: 'Datos', TamanoLetras: props.fontSize },
              tablas: {},
              head: [headPdf],
              body: bodyPdf,
              styles:   {fontSize: props.fontSize,},

            },
          ],
        },
      }

      pdfRef.value.generarPDF(datosInit)
    }

    return {
      icons: {
        mdiFileExcel,
        mdiFilePdf,
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiEye,
      },
      headers,
      datos,
      columasSelect,
      menu,
      exportarDataExcel,
      exportandoExel,
      search,
      GetEditar,
      GetVer,
      GetEliminar,
      pdfRef,
      ImprimirPDF,
      findObjectByLabel,
      FuncionesGenerales,
    }
  },
}
</script>